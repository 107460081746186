import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Document } from '../../../../Classes/Document';
import { Package } from '../../../../Classes/Package';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import { Loading } from '../../../../components/Loading/Loading';
import * as styles from './Summary.module.scss';

interface Props {
  Packages: Package[];
}

const PackagesSummary: React.FC<Props> = ({ Packages }) => {
  const [documents, setDocuments] = useState<Document[]>([] as Document[]);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    if (Packages) {
      let docs: Document[] = [] as Document[];
      Packages.map(pack => {
        pack.Documents.map(doc => {
          docs.push(doc);
        })
      })
      setDocuments(docs);
    }
  }, [Packages])

  const isInSilver = (doc: Document) => {
    let pack: Package | undefined = Packages.find(p => p.ID === doc.PackageID);
    if (pack?.Title.toLowerCase() === "silver" || pack?.isDefault)
      return true;
    return false;
  }

  const isInBronze = (doc: Document) => {
    let pack: Package | undefined = Packages.find(p => p.ID === doc.PackageID);
    if (pack?.Title.toLowerCase() === "bronze" || pack?.isDefault)
      return true;
    return false;
  }

  return (<>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>PrimeHR :: Packages Summary</title><meta charSet='utf-8' />
    </Helmet>

    <Banner />
    <Breadcrumb Trail={[{ To: "/Packages", Text: "Packages" }, { To: "", Text: "Packages Summary" }]} />

    <div className={styles.Search}>
      <div className={styles.SearchInput}>
        <i><FontAwesomeIcon icon={faSearch} /></i>
        <input type="search" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
    </div>

    <div className={styles.Table}>
      <div className={styles.TableInner}>
        <div className={styles.Row}>
          <div className={styles.Cell}>
            <h1>Document</h1>
          </div>
          <Link to="/Packages/Bronze" className={styles.Cell}>
            <h1>Bronze</h1>
          </Link>
          <Link to="/Packages/Silver" className={styles.Cell}>
            <h1>Silver</h1>
          </Link>
          <Link to="/Packages/Gold" className={styles.Cell}>
            <h1>Gold</h1>
          </Link>
        </div>
        {documents.length ?
          documents.map(doc => {
            return doc.Title.toLowerCase().includes(search.toLowerCase()) ? <Link key={`document-${doc.ID}`} to={`/Document/${doc.Title.replace(/ /g, "-")}`} className={styles.Row}>
              <div className={styles.Cell}>
                <h2>{doc.Title}</h2>
              </div>
              <div className={styles.Cell}>
                {isInBronze(doc) ? <i className={styles.Green}><FontAwesomeIcon icon={faCheckCircle} /></i> : <i><FontAwesomeIcon icon={faTimesCircle} /></i>}
              </div>
              <div className={styles.Cell}>
                {isInSilver(doc) ? <i className={styles.Green}><FontAwesomeIcon icon={faCheckCircle} /></i> : <i><FontAwesomeIcon icon={faTimesCircle} /></i>}
              </div>
              <div className={styles.Cell}>
                <i className={styles.Green}><FontAwesomeIcon icon={faCheckCircle} /></i>
              </div>
            </Link> : <></>
          })
          : <Loading />
        }
      </div>
    </div>
  </>);
};

export default PackagesSummary;
