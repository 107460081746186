import React from 'react';
import { Package } from '../../Classes/Package';
import Layout from '../Layout/layout';
import Summary from '../../Views/Website/Packages/Summary/Summary';

const SummaryPage: React.FC = (props: any) => {
  const Packages: Package[] = props.pageContext.Packages;

  return (
    <Layout>
      <Summary Packages={Packages} />
    </Layout>
  );
};

export default SummaryPage;
